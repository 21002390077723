import { Col, Row } from 'antd'
import React, { ReactElement, useContext } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import _ from 'lodash'
import { useStaticQuery, graphql } from 'gatsby'
import {
  Button,
  ContentContainer,
  SectionWrapper,
  TitleBold,
  TitleHeader,
} from '~components/homepage/styled/shared'

import {
  paragraphSize,
  smallScreenOffset,
} from '~components/homepage/styled/constants'

import LanguageContext from '~components/homepage/context/language-context'

import { Align } from '~components/homepage/styled/types'
import { ImageNode, ImagesQueryData } from '~src/types/graphql-types'

const CenterContent = styled(Col)`
  text-align: center;
  color: #2d2d2d;
  font-size: ${paragraphSize};
  position: relative;
`

const FloatedRow = styled(Row)`
  transform: translateY(-50%);
  width: 100%;
  margin: 0 !important;
`

const threeSectionGrid = {
  md: { span: 8, offset: 0 },
  lg: { span: 8, offset: 0 },
}

const GalleryImage = styled(Col)`
  & > div {
    height: 250px;
  }
`

const WorkshopSection = (): ReactElement => {
  const { t } = useContext(LanguageContext)

  const data = useStaticQuery<ImagesQueryData>(
    graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 50, maxHeight: 250) {
                  originalName
                  ...GatsbyImageSharpFluid
                }
              }
              name
            }
          }
        }
      }
    `
  )

  const imageNames = ['zrob-noz-2', 'zrob-noz-3', 'zrob-noz-1']

  const preparedImages = imageNames.map((name: string): object =>
    _.find(
      data.allFile.edges,
      (sharpNode: any): boolean => sharpNode.node.name === name
    )
  )

  const workshopGallery = preparedImages.map(
    (
      {
        node,
      }: {
        node: ImageNode
      },
      idx
    ): ReactElement => {
      // Hide all images instead first on small screens.
      const hideOnSmall = idx === 0 ? { span: 20, offset: 2 } : 0
      return (
        <GalleryImage
          {...threeSectionGrid}
          xs={hideOnSmall}
          sm={hideOnSmall}
          key={`workshop-image-${node.name}`}
        >
          <Img fluid={node.childImageSharp.fluid} alt="" />
        </GalleryImage>
      )
    }
  )

  const WorkshopContentContainer = styled(ContentContainer)`
    overflow: inherit;
  `

  return (
    <SectionWrapper bgColor="#f4f2ee">
      <WorkshopContentContainer padding="0px">
        <FloatedRow gutter={24} id="workshop">
          <CenterContent>{workshopGallery}</CenterContent>
        </FloatedRow>
        <FloatedRow>
          <CenterContent
            xs={smallScreenOffset}
            sm={smallScreenOffset}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
          >
            <TitleHeader size="10px" align={Align.center} margin="0px 0 10px 0">
              {t('workshop.title1')}
            </TitleHeader>
            <TitleBold size="15px" align={Align.center}>
              {t('workshop.title2')}
            </TitleBold>
            <Button
              href="https://zrobnoz.wordpress.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('workshop.buttonText')}
            </Button>
          </CenterContent>
        </FloatedRow>
      </WorkshopContentContainer>
    </SectionWrapper>
  )
}

export default WorkshopSection
