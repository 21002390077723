import { Col, Modal } from 'antd'
import _ from 'lodash'
import React, { ReactElement, useState, useContext } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import {
  screenSizes,
  mediaQueries,
} from '~components/homepage/styled/constants'
import {
  ContentContainer,
  SectionWrapper,
} from '~components/homepage/styled/shared'
import LanguageContext from '~components/homepage/context/language-context'
import { SVGImage } from '~components/homepage/svg/SVGImage'

import sharedWoodModel from '~src/models/wood/Shared.wood'
import sharedMetalModel from '~src/models/metals/Shared.metal'

import { ImagesQueryData } from '~src/types/graphql-types'
import {
  MaterialCol,
  MaterialRow,
  MaterialGridContainer,
  MaterialDescription,
  RenderVersatilityBar,
  BranchTitle,
} from '../styled/grid-materials'
import { KlosySVGId } from '../svg/types/svg'

const CustomMaterialCol = styled(MaterialCol)`
  max-width: 133px;
  width: 133px;
  min-width: 90px;
  float: inherit;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }

  @media (max-width: ${mediaQueries.small}) {
    & {
      width: 160px;
    }
  }

  .material-image {
    width: 85px;
    height: 85px;

    @media (max-width: ${mediaQueries.small}) {
      & {
        width: 85px !important;
        height: 85px;
      }
    }
  }
`

const CustomMaterialDescription = styled(MaterialDescription)`
  .params {
    padding-top: 7px;
  }
`

const CustomContentContainer = styled(ContentContainer)`
  padding-top: 10px;
  padding-bottom: 100px;
`

const MaterialGridSection = (): ReactElement => {
  const { t, lang } = useContext(LanguageContext)
  const [activeMaterial, setActiveMaterial] = useState(null)

  const data = useStaticQuery<ImagesQueryData>(
    graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 50, maxHeight: 85) {
                  originalName
                  ...GatsbyImageSharpFluid
                }
              }
              name
            }
          }
        }
      }
    `
  )

  const findImage = (src: string): ReactElement => {
    const imgNode = _.find(
      data.allFile.edges,
      (sharpNode): boolean => sharpNode.node.name === src
    )

    return <Img fluid={imgNode.node.childImageSharp.fluid} alt="" />
  }

  const renderDescription = (material): ReactElement => (
    <CustomMaterialDescription
      className="desktop-description"
      type="flex"
      justify="center"
      align="stretch"
    >
      <button
        type="submit"
        className="close-button"
        onClick={(): void => setActiveMaterial(null)}
      >
        <SVGImage svgProps={[KlosySVGId.cross, 'close', 12, 12]} />
      </button>

      <Col className="knife-name" md={{ span: 15, offset: 0 }}>
        {material.title[lang]}
      </Col>

      <Col className="description-text" md={{ span: 15, offset: 0 }}>
        {_.map(
          material.description[lang],
          (paragraph, i): ReactElement => (
            <p key={`paragraph-material-${material.name}-${i}`}>
              {' '}
              {paragraph}{' '}
            </p>
          )
        )}
      </Col>
      <Col md={{ span: 1, offset: 0 }}> </Col>
      <Col className="params" md={{ span: 8, offset: 0 }}>
        <div className="knife-params">
          {material.care && (
            <div>
              <span className="bold">{t('material-grid.care')}</span>:{' '}
              {material.care[lang]}
            </div>
          )}
          {material.oilCare && (
            <div>
              <span className="bold">{t('material-grid.oilCare')}</span>:{' '}
              {material.oilCare[lang]}
            </div>
          )}
          {material.weight && (
            <div>
              <span className="bold">{t('material-grid.weight')}</span>:{' '}
              {material.weight[lang]}
            </div>
          )}
          {material.endurance && (
            <div>
              <span className="bold">{t('material-grid.endurance')}</span>:
              {RenderVersatilityBar(material.endurance)}
            </div>
          )}

          {material.sharpening && (
            <div>
              <span className="bold">{t('material-grid.sharpening')}</span>:{' '}
              {material.sharpening[lang]}
            </div>
          )}
          {material.stainless && (
            <div>
              <span className="bold">{t('material-grid.stainless')}</span>:{' '}
              {RenderVersatilityBar(material.stainless)}
            </div>
          )}
          {material.hardness && (
            <div>
              <span className="bold">{t('material-grid.hardness')}</span>:{' '}
              {RenderVersatilityBar(material.hardness)}
            </div>
          )}
          {material.edgeRetention && (
            <div>
              <span className="bold">{t('material-grid.edgeRetention')}</span>:{' '}
              {RenderVersatilityBar(material.edgeRetention)}
            </div>
          )}
          {material.coreStainless && (
            <div>
              <span className="bold">{t('material-grid.coreStainless')}</span>:{' '}
              {RenderVersatilityBar(material.coreStainless)}
            </div>
          )}
        </div>
      </Col>
    </CustomMaterialDescription>
  )

  const renderMaterialItem = (rowChunk): ReactElement =>
    rowChunk.map(
      (material): ReactElement => {
        const isActive: boolean =
          activeMaterial && material.name === activeMaterial.name

        return (
          <CustomMaterialCol
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 4, offset: 0 }}
            lg={{ span: 4, offset: 0 }}
            onClick={(): void => setActiveMaterial(material)}
            className={`${isActive ? 'active' : ''}`}
            key={material.name}
          >
            <div className={`image material-image `}>
              {findImage(material.img)}
            </div>
            <div className="knife-name"> {material.title[lang]} </div>
            <div className="price material-price"> {material.price[lang]} </div>
            <div className="info-sign">
              <SVGImage
                svgProps={[KlosySVGId.infoSign, 'info-sign-icon', 17, 17]}
              />
            </div>
            <div
              className={`stick material-stick ${isActive && 'active-stick'}`}
            >
              {' '}
            </div>
          </CustomMaterialCol>
        )
      }
    )

  const renderMaterialRow = _.chunk(
    [...sharedWoodModel, ...sharedMetalModel],
    6
  ).map(
    (row): ReactElement => {
      const showDescription = _.find(
        row,
        (k): boolean => activeMaterial && k.name === activeMaterial.name
      )
        ? renderDescription(activeMaterial)
        : null

      return (
        <span key={`row-material-${_.head(row).name}`}>
          <BranchTitle className={`${!row[0].oilCare && 'blade-title'}`}>
            <SVGImage svgProps={[KlosySVGId.klosSide, 'left-branch', 68, 10]} />
            <span>
              {/* its very tricky checking */}
              {row[0].oilCare
                ? t('material-grid.titleHandle')
                : t('material-grid.titleBlade')}
            </span>
            <SVGImage
              svgProps={[KlosySVGId.klosSide, 'right-branch', 68, 10]}
            />
          </BranchTitle>
          <MaterialRow disableLine>{renderMaterialItem(row)}</MaterialRow>
          {showDescription}
        </span>
      )
    }
  )

  const renderDescriptionModal = (
    <Modal
      wrapClassName="description-modal"
      visible={activeMaterial && window.innerWidth <= screenSizes.small}
      footer={null}
      title={null}
      closeIcon={null}
      closable={false}
      width="100%"
      style={{
        top: '0px',
        margin: '0px',
        width: '100%',
        height: '100vh',
        maxWidth: '100%',
      }}
      bodyStyle={{
        padding: '0px',
        height: '100vh',
      }}
    >
      {activeMaterial ? renderDescription(activeMaterial) : null}
    </Modal>
  )

  return (
    <SectionWrapper bgColor="#EAE6DD" id="gridKnife">
      <CustomContentContainer padding="0px">
        <MaterialGridContainer>{renderMaterialRow}</MaterialGridContainer>
        {renderDescriptionModal}
      </CustomContentContainer>
    </SectionWrapper>
  )
}

export default MaterialGridSection
